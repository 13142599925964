// main.js

// Cog press core components.
import Disclosure from "./scripts/utils/Disclosure.js";
import Dialog from "./scripts/utils/Dialog.js";

import Accordion from "./scripts/components/Accordion.js";
import CogBooking from "./scripts/components/CogBooking.js";
import SiteHeader from "./scripts/components/SiteHeader.js";

// Flex components
import MediaGallery from "./scripts/components/flex/MediaGallery.js";
import SupportBlock from "./scripts/components/flex/SupportBlock.js";
import PeopleBlock from "./scripts/components/flex/People.Js";
import RelatedContent from "./scripts/components/flex/RelatedContent.js";
import ShuffleGrid from "./scripts/components/flex/ShuffleGrid.js";
import DiscoveryBlock from "./scripts/components/flex/DiscoveryBlock.js";
import Membership from "./scripts/components/flex/Membership.js";

// Post types and templates.
import BlogVideo from "./scripts/components/templates/BlogVideo.js";
import YouTubeHeroVideo from "./scripts/components/templates/YouTubeHeroVideo.js";
import SearchResults from "./scripts/components/templates/SearchResults.js";
import VimeoHero from "./scripts/components/templates/VimeoHero.js";

import SupportUsButton from "./scripts/components/SupportUsButton.js";

// Global announcement.
import SiteBanner from "./scripts/components/templates/SiteBanner.js";

// Utils
import MobileDisplay from "./scripts/utils/MobileDisplay.js";
import LoadMoreInstances from "./scripts/utils/LoadMoreInstances.js";
import TextExpand from "./scripts/utils/TextExpand.js";
import FocusImage from "./scripts/utils/FocusImage.js";

//import TNewUserSatus from "./scripts/utils/TNewUserStatus.js";
import CogCredits from "./scripts/components/CogCredits.js";

document.addEventListener("DOMContentLoaded", () => {});
