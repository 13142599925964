import Disclosure from "./Disclosure";

class TextExpand {
    constructor(element) {
        this.element = element;
        this.constrainedHeight = window
            .getComputedStyle(this.element)
            .getPropertyValue("--text-expand--constrained-height");

        if (!this.constrainedHeight) {
            return;
        }

        // Check if the element has an id, if not, add a unique one
        if (!this.element.id) {
            this.element.id = "text-expand-" + Math.floor(Math.random() * 100);
        }

        // Convert the constrained height to pixels if it's set in lh units
        if (this.constrainedHeight.includes("lh")) {
            this.constrainedHeight = parseFloat(
                window
                    .getComputedStyle(this.element)
                    .getPropertyValue("--text-expand--constrained-height"),
            );

            const lineHeight = parseFloat(
                window
                    .getComputedStyle(this.element)
                    .getPropertyValue("line-height"),
            );
            this.constrainedHeight = this.constrainedHeight * lineHeight;
        }

        // Check if the element's height is over the constrained height
        if (this.element.clientHeight <= this.constrainedHeight) {
            return;
        }

        this.createTextToggle();
    }

    createTextToggle() {
        const button = document.createElement("button");
        button.setAttribute("aria-expanded", "false");
        button.textContent = "Read more";
        button.classList.add("text-expand__toggle");
        button.setAttribute("aria-controls", this.element.id);

        this.element.after(button);

        this.disclosure = new Disclosure(this.element, {
            setHiddenAttribute: false,
            on: {
                expandbegin: () => {
                    button.textContent = "Show less";
                    console.log("expand begins");

                    this.element.animate(
                        [{ height: `${this.element.scrollHeight}px` }],
                        {
                            duration: 250,
                            fill: "both",
                            easing: "ease",
                        },
                    );
                },
                collapsebegin: () => {
                    button.textContent = "Read more";

                    this.element.animate(
                        [{ height: `${this.constrainedHeight}px` }],
                        {
                            duration: 250,
                            fill: "both",
                            easing: "ease",
                        },
                    );
                },
            },
        });

        this.element.classList.add("text-expand--active");
    }
}

function setMainTextProps(mainText) {
    mainText.classList.add("text-expand");
    mainText.setAttribute("aria-hidden", "true");
    mainText.style.setProperty("--text-expand--constrained-height", "23.5lh");
}

document.addEventListener("DOMContentLoaded", () => {
    const isBlogPost = document.body.classList.contains("single-post");
    const isJob = document.body.classList.contains("single-job");

    const mainText = document.getElementById("section--text");
    const is_mobile = window.matchMedia("(max-width: 576px)").matches;
    if (is_mobile) {
        if ((isBlogPost && mainText) || (isJob && mainText)) {
            setMainTextProps(mainText);
        }
    }
});

document.addEventListener("DOMContentLoaded", () => {
    const items = document.querySelectorAll(".text-expand");
    items.forEach((item) => {
        new TextExpand(item);
    });
});

// This deals with text-expand class added on the fly. e.g for Mobile on jobs and blog posts.
var observer = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
        mutation.addedNodes.forEach(function (addedNode) {
            if (addedNode.nodeType === 1) {
                const items = addedNode.querySelectorAll(".text-expand");

                items.forEach((item) => {
                    new TextExpand(item);
                });
            }
        });
    });
});

let bodyElement = document.body;

if (bodyElement) {
    observer.observe(document.body, { childList: true, subtree: true });
}
