/**
 * Dialog utility to enable more accessible and feature-rich dialogs.
 * This class is a work in progress.
 */
class Dialog {
    constructor(element) {
        this.el = element;
        this.dialogName = this.el.getAttribute("data-dialog");
        this.dialogContent = this.el.querySelector("[data-dialog-content]");
        this.type = this.el.dataset.dialogType;
        this.templateId = this.el.dataset.template;

        this.init();
    }

    init() {
        const dialogShowElements = document.querySelectorAll(
            "[data-dialog-show='" + this.dialogName + "']",
        );

        const dialogHideElements =
            this.el.querySelectorAll("[data-dialog-hide]");

        dialogShowElements.forEach((element) => {
            element.addEventListener("click", (e) => {
                this.open(e);
            });
        });

        dialogHideElements.forEach((element) => {
            element.addEventListener("click", (e) => {
                this.close(e);
            });
        });

        this.setScrollbarWidth();
    }

    open(e) {
        this.fixBody();

        // Case: Burger menu is open, then search is activated.
        if (
            "searchDialog" == this.dialogName &&
            document.body.classList.contains("menuDialog-dialog-is-open")
        ) {
            // Burger menu dialog has to be closed.
            document.body.classList.remove(`menuDialog-dialog-is-open`);
            const menuDialog = document.querySelector(
                '[data-dialog="menuDialog"]',
            );

            if (menuDialog) {
                menuDialog.setAttribute("aria-hidden", "true");
                menuDialog.setAttribute("data-type", "");
            }
        }

        // Case: Search is open, then burger menu is activated.
        if (
            "menuDialog" == this.dialogName &&
            document.body.classList.contains("searchDialog-dialog-is-open")
        ) {
            // Search dialog has to be closed.
            document.body.classList.remove(`searchDialog-dialog-is-open`);

            const searchDialog = document.querySelector(
                '[data-dialog="searchDialog"]',
            );

            if (searchDialog) {
                searchDialog.setAttribute("aria-hidden", "true");
                searchDialog.setAttribute("data-type", "");
            }
        }

        document.body.classList.add("dialog-is-open");
        document.body.classList.add(`${this.dialogName}-dialog-is-open`);

        this.el.setAttribute("aria-hidden", "false");

        if (this.dialogContent) {
            this.dialogContent.innerHTML = document.getElementById(
                this.templateId,
            ).innerHTML;
        }

        if (this.type) {
            this.el.setAttribute("data-type", type);
        }
    }

    close() {
        if (this.dialogContent) {
            this.dialogContent.innerHTML = "";
        }

        this.unfixBody();

        document.body.classList.remove("dialog-is-open");
        document.body.classList.remove(`${this.dialogName}-dialog-is-open`);

        this.el.setAttribute("aria-hidden", "true");
        this.el.setAttribute("data-type", "");
    }

    fixBody() {
        document.documentElement.classList.add("no-scroll");
    }

    unfixBody() {
        document.documentElement.classList.remove("no-scroll");
    }

    setScrollbarWidth() {
        // Use this to stop elements jumping when dialog is opened
        document.documentElement.style.setProperty(
            "--scrollbar-width",
            window.innerWidth - document.documentElement.clientWidth + "px",
        );
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const dialogElements = document.querySelectorAll("[data-dialog]");

    dialogElements.forEach((element) => {
        new Dialog(element);
    });
});
