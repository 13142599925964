class YouTubeHeroVideo {
    constructor(el) {
        this.videoContainer = el;

        this.isMobile = window.matchMedia("(max-width: 576px)").matches;

        this.videoID = this.videoContainer.dataset.heroVideoId ?? false;

        this.playersVars = {
            autoplay: 1,
            mute: 1,
            controls: 0,
            rel: 0,
            showinfo: 0,
            loop: 1,
            //playlist: this.videoID, // For looping the video
        };

        if (this.isMobile) {
            this.playersVars = {
                ...this.playersVars,
                autoplay: 0,
            };
        }

        this.loadYouTubeAPI();

        this.onPlayerReady = this.onPlayerReady.bind(this);
    }

    loadYouTubeAPI() {
        // Check if the API is already loaded
        if (typeof YT === "undefined" || typeof YT.Player === "undefined") {
            const tag = document.createElement("script");
            tag.src = "https://www.youtube.com/iframe_api";
            document.head.appendChild(tag);

            // https://developers.google.com/youtube/iframe_api_reference#Loading_a_Video_Player
            // Set up the API ready callback
            window.onYouTubeIframeAPIReady =
                this.onYouTubeIframeAPIReady.bind(this);
        } else {
            // API already loaded, initialize the player directly
            this.onYouTubeIframeAPIReady();
        }
    }

    onYouTubeIframeAPIReady() {
        this.player = new YT.Player("player", {
            videoId: this.videoID,
            playerVars: this.playersVars,
            events: {
                onReady: this.onPlayerReady,
            },
        });
    }

    onPlayerReady() {
        this.initVideoControls();

        if (this.isMobile) {
            this.playButton.ariaHidden = false;
            this.pauseButton.ariaHidden = true;
        }

        this.playButton?.addEventListener("click", (event) => {
            this.player.playVideo();
            this.toggleControlBtns();
        });

        this.pauseButton?.addEventListener("click", (event) => {
            this.player.pauseVideo();
            this.toggleControlBtns();
        });

        this.unmuteButton?.addEventListener("click", (event) => {
            this.player.unMute();
            this.toggleControlBtns("mute-unmute");
        });

        this.muteButton?.addEventListener("click", (event) => {
            this.player.mute();
            this.toggleControlBtns("mute-unmute");
        });
    }

    initVideoControls() {
        if (this.videoID) {
            this.videoControls = document.querySelectorAll(
                `[aria-controls="${this.videoID}"]`,
            );

            this.videoControls?.forEach((button) => {
                if (button.dataset.buttonType == "play") {
                    this.playButton = button;
                } else if (button.dataset.buttonType == "pause") {
                    this.pauseButton = button;
                } else if (button.dataset.buttonType == "unmute") {
                    this.unmuteButton = button;
                } else {
                    this.muteButton = button;
                }
            });
        }
    }

    toggleControlBtns(which = "play-pause") {
        if ("play-pause" === which) {
            this.playButton.ariaHidden = !this.stringToBoolean(
                this.playButton.ariaHidden,
            );

            this.pauseButton.ariaHidden = !this.stringToBoolean(
                this.pauseButton.ariaHidden,
            );
        } else {
            this.muteButton.ariaHidden = !this.stringToBoolean(
                this.muteButton.ariaHidden,
            );

            this.unmuteButton.ariaHidden = !this.stringToBoolean(
                this.unmuteButton.ariaHidden,
            );
        }
    }

    stringToBoolean(str) {
        return str.toLowerCase() === "true" ? true : false;
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const videoContainer = document.querySelectorAll("[data-hero-video-id]");

    if (videoContainer) {
        videoContainer.forEach((el) => {
            new YouTubeHeroVideo(el);
        });
    }
});

export default YouTubeHeroVideo;
