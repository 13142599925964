import Cookies from "js-cookie";

class SiteBanner {
    constructor(el) {
        this.global_banner = el;

        this.close_btn = el.querySelector("[data-banner-close-btn]");

        this.maybeShowBanner();

        if (this.close_btn) {
            this.close_btn.addEventListener("click", this.closeBanner);
        }
    }

    closeBanner(e) {
        e.preventDefault();

        if (!Cookies.get("announcement-close")) {
            Cookies.set("announcement-close", true);

            const siteBanner = document.querySelector("[data-site-banner]");
            if (siteBanner) {
                siteBanner.remove();
            }
        }
    }

    maybeShowBanner() {
        if (!this.global_banner) {
            return false;
        }

        if (!Cookies.get("announcement-close")) {
            this.global_banner.style.display = "";
        } else {
            this.global_banner.remove();
        }
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const siteBanner = document.querySelector("[data-site-banner]");

    if (siteBanner) {
        new SiteBanner(siteBanner);
    }
});

export default SiteBanner;
