class CogCredits extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        const toggle = this.querySelector(".cog-credits__toggle");

        if (toggle) {
            toggle.addEventListener("click", (e) => {
                e.preventDefault();

                this.setAttribute("open", this.getAttribute("open") !== "true");
            });
        }
    }
}

if (!customElements.get("cog-credits")) {
    customElements.define("cog-credits", CogCredits);
}
