class PeopleBlock {
    constructor(element) {
        this.peopleBlock = element;
        this.cards = element.querySelectorAll("article");

        this.setCardsClickable(this.cards);
    }

    setCardsClickable(cards) {
        if (cards) {
            cards.forEach((card) => {
                let cardUrl = card.dataset.cardUrl;
                let cardTitle = card.querySelector(".card__heading");

                if (cardTitle) {
                    cardTitle.addEventListener("click", (event) => {
                        if (cardUrl) {
                            window.location.assign(cardUrl);
                        }
                    });
                }
            });
        }
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const peopleBlocks = document.querySelectorAll(
        ".people-component__cards.grid",
    );

    if (peopleBlocks) {
        peopleBlocks.forEach((element) => {
            new PeopleBlock(element);
        });
    }
});

export default PeopleBlock;
