class FocusImage {
    constructor(image) {
        this.image = image;

        this.init();
    }

    init() {
        this.setProperties();
    }

    setProperties() {
        this.image.style.setProperty(
            "--image-init-height",
            this.image.offsetHeight + "px",
        );
        this.image.style.setProperty(
            "--image-init-width",
            this.image.offsetWidth + "px",
        );
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const images = document.querySelectorAll("[data-focus-image]");
    // console.log("focus image runnin", images);

    images?.forEach((image) => {
        new FocusImage(image);
    });
});
