import { slick } from "@accessible360/accessible-slick";

class RelatedContent {
    constructor(element) {
        // Class properties.
        this.relContentBlock = element;

        this.slickCarousel = {};

        // Carousel arrows.
        // Desktop.
        this.backArrow = `<svg width="34" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m21.882 23.507-1.847-1.84 8.34-8.355H.611v-2.638h27.764l-8.347-8.34L21.882.478 33.39 12 21.882 23.507Z" fill="#1C1B1F"/></svg>`;

        this.nextArrow = `<svg width="34" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m21.882 23.507-1.847-1.84 8.34-8.355H.611v-2.638h27.764l-8.347-8.34L21.882.478 33.39 12 21.882 23.507Z" fill="#1C1B1F"/></svg>`;

        this.init();
    }

    init() {
        this.createCarousel();
    }

    createCarousel() {
        let props = {
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: false,
            arrows: true,
            infinite: false,
            speed: 300,
            rows: 0,
            arrowsPlacement: "beforeSlides",
            prevArrow:
                '<button type="button" data-control-prev><span class="sr-text">Previous</span>' +
                this.backArrow +
                "</button>",
            nextArrow:
                '<button type="button" data-control-next><span class="sr-text">Next</span>' +
                this.nextArrow +
                "</button>",
        };

        // Init slick carousel.
        let carousel = $(this.relContentBlock).slick(props);

        this.slickCarousel = carousel;
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const relContentCarousels = document.querySelectorAll(
        '[data-carousel="related-content"]',
    );

    relContentCarousels.forEach((element) => {
        new RelatedContent(element);
    });
});

export default RelatedContent;
