import { slick } from "@accessible360/accessible-slick";

// Note: original code is copied from https://bitbucket.org/CogDesign14/everyman_playhouse_theme/src/develop/src/scripts/components/Media.js

class MediaGallery {
    constructor(element) {
        // Class properties.
        this.el = element;

        // Carousel arrows.
        // Desktop.
        this.backArrow = `<svg width="34" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m21.882 23.507-1.847-1.84 8.34-8.355H.611v-2.638h27.764l-8.347-8.34L21.882.478 33.39 12 21.882 23.507Z" fill="#1C1B1F"/></svg>`;

        this.nextArrow = `<svg width="34" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m21.882 23.507-1.847-1.84 8.34-8.355H.611v-2.638h27.764l-8.347-8.34L21.882.478 33.39 12 21.882 23.507Z" fill="#1C1B1F"/></svg>`;

        // Mobile.
        this.mobBackArrow = `<svg width="11" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m8.146 9.983-7.89-7.9L1.67.65l9.322 9.332-9.322 9.321-1.412-1.432 7.89-7.89Z" fill="#1C1B1F"/></svg>`;

        this.mobNextdArrow = `<svg width="11" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m8.146 9.983-7.89-7.9L1.67.65l9.322 9.332-9.322 9.321-1.412-1.432 7.89-7.89Z" fill="#1C1B1F"/></svg>`;

        this.removeVideoFromDialog = this.removeVideoFromDialog.bind(this);

        this.init();
    }

    init() {
        this.createCarousel();
    }

    createCarousel() {
        let nav_id, nav_for, props;

        nav_id = this.el.dataset.mainCarousel
            ? this.el.dataset.mainCarousel
            : this.el.dataset.navCarousel;

        nav_for = this.el.dataset.mainCarousel
            ? '[data-nav-carousel="' + nav_id + '"]'
            : '[data-main-carousel="' + nav_id + '"]';

        if (this.el.dataset.mainCarousel) {
            props = {
                asNavFor: nav_for ?? null,
                dots: false,
                arrows: true,
                infinite: false,
                speed: 300,
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: false,
                arrowsPlacement: "beforeSlides",
                fade: true,
                speed: 1000,
                prevArrow:
                    '<button type="button" data-control-prev><span class="sr-text">Previous</span>' +
                    this.backArrow +
                    "</button>",
                nextArrow:
                    '<button type="button" data-control-next><span class="sr-text">Next</span>' +
                    this.nextArrow +
                    "</button>",
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            prevArrow:
                                '<button type="button" data-control-prev><span class="sr-text">Previous</span>' +
                                this.mobBackArrow +
                                "</button>",
                            nextArrow:
                                '<button type="button" data-control-next><span class="sr-text">Next</span>' +
                                this.mobNextdArrow +
                                "</button>",
                        },
                    },
                ],
            };
        } else {
            // Its nav carousel.
            props = {
                asNavFor: nav_for ?? null,
                dots: false,
                arrows: false,
                infinite: true,
                speed: 300,
                slidesToShow: 4,
                slidesToScroll: 1,
                adaptiveHeight: false,
                instructionsText: `Changing the current slide of this carousel will change
                the current slide of the preceding main image carousel`,
                regionLabel: `thumbnail carousel`,
                // responsive: [
                //     { breakpoint: 768, settings: { slidesToShow: 3 } },
                // ],
            };
        }

        // Init slick carousel.
        let carousel = $(this.el).slick(props);

        this.el.carousel = carousel;

        // Events handlers.
        const active_class = "media-item-active";

        // Events for navigation carousel.
        if (this.el.dataset.navCarousel) {
            // Activate the first item of the carousel.
            this.el.querySelector(".slick-active").classList.add(active_class);

            const buttons = this.el.querySelectorAll("[data-go-to]");

            buttons.forEach((button) => {
                // Attach event to handle when a btn is clicked/touch on the nav,
                // then the picture is displayed in the main carousel.
                button.addEventListener("click", () => {
                    const carousel_index = button.dataset.carouselIndex;
                    const slide_index = button.dataset.goTo - 1;

                    $('[data-nav-carousel="' + carousel_index + '"]').slick(
                        "slickGoTo",
                        slide_index,
                    );
                });
            });
        }

        // Events from main carousel.
        if (this.el.dataset.mainCarousel) {
            this.handleVideoDialog();

            carousel.on("afterChange", function (e, index) {
                let carousel_index = carousel.data("main-carousel"),
                    slide_index = carousel.slick("slickCurrentSlide");

                let active = document.querySelector("." + active_class);
                if (active) {
                    active.classList.remove(active_class);
                }

                document
                    .querySelector(
                        '[data-nav-carousel="' +
                            carousel_index +
                            '"] [data-slick-index="' +
                            slide_index +
                            '"]',
                    )
                    .classList.add(active_class);
            });
        }

        // End Events handlers.
    }

    handleVideoDialog() {
        const videoButtons = this.el.querySelectorAll("[data-video-id]");
        if (!videoButtons) {
            return false;
        }

        videoButtons.forEach((button) => {
            let videoId = button.dataset.videoId;
            let dialogForId = this.el.querySelector(
                `[data-dialog="${videoId}"]`,
            );

            // Open dialog
            button.addEventListener("click", (event) => {
                this.addVideoToDialog(dialogForId);

                dialogForId?.showModal();
            });

            // Get dialog close btn
            let dialogCloseBtn = dialogForId.querySelector(
                "[data-dialog-close]",
            );

            // Close dialog (inside btn)
            dialogCloseBtn?.addEventListener("click", (event) => {
                this.removeVideoFromDialog(dialogForId);

                dialogForId?.close();
            });

            // Handle Escape key.
            if (dialogCloseBtn) {
                document.addEventListener("keyup", function (event) {
                    if (event.key === "Escape" || event.key === "Esc") {
                        dialogCloseBtn.click();
                    }
                });
            }
        });
    }

    addVideoToDialog(dialogForId) {
        const videoIframe = this.getVideofromTemplate(dialogForId);
        const container = dialogForId.querySelector("div.content");

        if (container && videoIframe) {
            container.appendChild(videoIframe);
        }
    }

    removeVideoFromDialog(dialogForId) {
        const videoIframe = dialogForId.querySelector("iframe");
        if (videoIframe) {
            videoIframe.remove();
        }
    }

    getVideofromTemplate(dialogForId) {
        const template = dialogForId.querySelector("template");
        const templateContent = template?.content;

        if (templateContent) {
            // Clone the content (deep clone to include children)
            const clonedContent = document.importNode(templateContent, true);

            return clonedContent;
        } else {
            return false;
        }
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const mediaCarousels = document.querySelectorAll(
        `[data-carousel="media"],[data-carousel="media-nav"],[data-carousel="single-media"]`,
    );

    mediaCarousels.forEach((element) => {
        new MediaGallery(element);
    });
});

export default MediaGallery;
