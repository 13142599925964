import Disclosure from "../utils/Disclosure.js";

class Accordion {
    constructor(element) {
        this.el = element;
        this.items = this.el.querySelectorAll(".accordion-item");
        this.disclosureItems = [];

        // Bail early - no accordion items.
        if (this.items.length < 1) {
            return;
        }

        this.init();
    }

    init() {
        this.items.forEach((item) => {
            const itemPanel = item.querySelector(
                ".accordion-item__content-panel",
            );

            if (item instanceof Element === false) {
                return;
            }

            const accordionDisclosure = new Disclosure(itemPanel, {
                setHiddenAttribute: false, // handled below
                on: {
                    expandbegin: () => {
                        itemPanel.removeAttribute("hidden");

                        this.disclosureItems.forEach((disclosure) => {
                            if (disclosure !== accordionDisclosure) {
                                disclosure.collapse();
                            }
                        });
                    },

                    expandend: () => {
                        itemPanel.style.height = `${itemPanel.scrollHeight}px`;
                    },

                    collapsebegin: () => {
                        // Listen for 'transitionend' once to set hidden attribute after height has transitioned.
                        itemPanel.addEventListener(
                            "transitionend",
                            () => itemPanel.setAttribute("hidden", true),
                            {
                                once: true,
                            },
                        );

                        itemPanel.style.height = "0px";
                    },
                },
            });

            // set height to ensure initial transition works.
            accordionDisclosure.isExpanded()
                ? (itemPanel.style.height = `${itemPanel.scrollHeight}px`)
                : (itemPanel.style.height = "0px");

            this.disclosureItems.push(accordionDisclosure);
        });
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const accordionElements = document.querySelectorAll(".accordion");

    accordionElements.forEach((element) => {
        new Accordion(element);
    });
});
