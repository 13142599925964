/**
 *
 *  Sample markup
 *
     <div class="img-wrapper" data-display-size="desktop" aria-hidden="false">
     This display on desktop <img src="image-2.jpeg" alt="" />
    </div>

    <div class="img-wrapper" data-display-size="mobile" aria-hidden="true" style="display:none">
      This display on mobile <img src="image-1.jpeg" alt="" />
    </div>
 *
 */
class MobileDisplay {
    constructor(el) {
        this.el = el;

        this.isMobile = window.matchMedia("(max-width: 1164px)").matches;

        this.displaySize = el.dataset.displaySize ?? false;

        this.handleElemDisplay();
    }

    handleElemDisplay() {
        if (this.isMobile) {
            if ("mobile" == this.displaySize) {
                this.showElem();
            } else if ("desktop" == this.displaySize) {
                this.hideElem();
            }
        } else {
            // It's not mobile.
            if ("desktop" == this.displaySize) {
                this.showElem();
            } else if ("mobile" == this.displaySize) {
                this.hideElem();
            }
        }
    }

    showElem() {
        this.el.ariaHidden = false;
        this.el.style.display = "";
    }

    hideElem() {
        this.el.ariaHidden = true;
        this.el.style.display = "none";
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const elemements = document.querySelectorAll("[data-display-size]");

    if (elemements) {
        elemements.forEach((el) => {
            new MobileDisplay(el);
        });
    }
});

export default MobileDisplay;
