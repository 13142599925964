class DiscoveryBlock {
    constructor(el) {
        this.el = el;

        this.isMobile = window.matchMedia("(max-width: 576px)").matches;
        this.cards = this.el.querySelectorAll("article.card");
        this.removeFromIndex = 3;

        this.removeCardsOnMobile();
    }

    removeCardsOnMobile() {
        if (this.isMobile && this.cards?.length > this.removeFromIndex) {
            const cardArr = Array.from(this.cards);

            for (let i = this.removeFromIndex; i < this.cards.length; i++) {
                if (cardArr[i]) {
                    cardArr[i].remove();
                }
            }
        }
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const discoveryBlocks = document.querySelectorAll(
        ".discovery-block__cards",
    );

    if (discoveryBlocks) {
        discoveryBlocks.forEach((block) => {
            new DiscoveryBlock(block);
        });
    }
});

export default DiscoveryBlock;
