class Membership {
    constructor(el) {
        this.el = el;

        this.isMobile = window.matchMedia("(max-width: 576px)").matches;

        this.forms = el.querySelectorAll("form");

        if (!this.forms) {
            return false;
        }

        this.forms?.forEach((form) => {
            form?.addEventListener("submit", (event) => {
                event.preventDefault();

                const formData = new FormData(form);

                let params_vals = {};
                // Add each name/value pair to the object
                for (let [name, value] of formData) {
                    params_vals[name] = value;
                }

                const submitter = event.submitter;

                // Join button submitted form.
                if (submitter.name == "membership-join") {
                    let optionEl;

                    if (params_vals["options"] == "Pay by card") {
                        optionEl = form.querySelector(
                            "input[value='Pay by card']",
                        );
                    } else {
                        optionEl = form.querySelector(
                            "input[value='Direct debit']",
                        );
                    }

                    const urlRedirect = optionEl.dataset.paymentUrl;
                    console.log("url to direct", urlRedirect);
                    if (urlRedirect) {
                        window.location.assign(urlRedirect);
                    }
                } else {
                    // Gift button submitted form.
                    if (submitter?.dataset.giftUrl) {
                        window.location.assign(submitter.dataset.giftUrl);
                    }
                }
            }); // End form submit event.
        });
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const membershipBlocks = document.querySelectorAll("section.memberships");

    if (membershipBlocks) {
        membershipBlocks.forEach((block) => {
            new Membership(block);
        });
    }
});

export default Membership;
