import A11yTabs from "a11y-tabs";

class SearchResults {
    constructor() {
        this.selectedTab = 0;

        this.a11yTabs = new A11yTabs(
            ".tab-list",
            '[role="tabpanel"]',
            this.selectedTab,
        );

        // Handle load more functionality on all tabs.
        const loadMoreBtns = document.querySelectorAll(
            "[data-load-more-btn-search]",
        );

        if (loadMoreBtns?.length) {
            loadMoreBtns.forEach((loadBtn) => {
                let howMany = parseInt(loadBtn.dataset.howManyItems);

                let previousSibling = loadBtn.previousElementSibling;
                if (previousSibling) {
                    let instances =
                        previousSibling.querySelectorAll("article.card");

                    this.initInstancesDisplay(instances, howMany);

                    this.handleDisplayInstances(loadBtn, instances);
                }
            });
        }
    }

    initInstancesDisplay(instances, howMany) {
        if (instances.length > howMany) {
            const iniHiddenInstances = [...instances].slice(howMany);

            if (iniHiddenInstances.length) {
                iniHiddenInstances.forEach((instance) => {
                    instance.classList.add("hidden-card");
                });
            }
        }
    }

    handleDisplayInstances(loadMoreBtn, instances) {
        loadMoreBtn.addEventListener("click", (e) => {
            e.preventDefault();

            this.displayNextChunk(loadMoreBtn, instances);
        });
    }

    displayNextChunk(loadMoreBtn, instances) {
        let howMany = parseInt(loadMoreBtn.dataset.howManyItems);
        let pageNum = parseInt(loadMoreBtn.dataset.pageNum);

        let chunk = [];

        let instancesArr = [...instances];

        let start = pageNum * howMany;
        let end = start + howMany;

        if (end < instancesArr.length) {
            chunk = instancesArr.slice(start, end);
        } else {
            chunk = instancesArr.slice(start);
            loadMoreBtn.dataset.allShown = "true";
        }

        loadMoreBtn.dataset.pageNum = parseInt(pageNum) + 1;

        this.showChunk(chunk);

        if (loadMoreBtn.dataset.allShown == "true") {
            // Remove button.
            loadMoreBtn.remove();
        }
    }

    showChunk(chunk) {
        chunk.forEach((instance) => {
            instance.classList.remove("hidden-card");
        });
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const isSearchPage = document.body.classList.contains("search");

    if (isSearchPage) {
        new SearchResults();
    }
});

export default SearchResults;
