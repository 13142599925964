import Player from "@vimeo/player";

class VimeoHero {
    constructor(el) {
        this.iframe = el;

        this.isMobile = window.matchMedia("(max-width: 576px)").matches;

        this.pauseBtn = document.getElementById("pause-btn");

        this.unMuteBtn = document.getElementById("unmute-btn");

        this.handleVideo();
    }

    handleVideo() {
        const vimeoPlayer = new Player(this.iframe);

        const pauseIcon = document.getElementById("pause-icon");
        const playIcon = document.getElementById("play-icon");

        const heroVideoContainer = document.querySelector(
            "[data-vimeo-container]",
        );

        const videoCoverImage = document.querySelector(
            "[data-home-video-cover-img]",
        );

        const featuredImage = document.querySelector(
            "[data-hero-feature-image]",
        );

        if (this.isMobile) {
            // Hide video container on load.
            heroVideoContainer.style.display = "none";

            vimeoPlayer.pause();

            // Pause icon visible.
            playIcon.style.display = "";
            pauseIcon.style.display = "none";

            // Homepage: If there's a video image cover, show it instead of featured image.
            if (videoCoverImage) {
                videoCoverImage.style.display = "";
                featuredImage.style.display = "none";
            }
        }

        this.handlePlayPause(
            vimeoPlayer,
            pauseIcon,
            playIcon,
            this.isMobile,
            heroVideoContainer,
        );

        this.handleUnmuteMute(vimeoPlayer);
    }

    handlePlayPause(
        vimeoPlayer,
        pauseIcon,
        playIcon,
        isMobile,
        heroVideoContainer,
    ) {
        if (this.pauseBtn && vimeoPlayer) {
            this.pauseBtn?.addEventListener("click", (e) => {
                vimeoPlayer
                    .getPaused()
                    .then(function (paused) {
                        if (paused) {
                            vimeoPlayer.play();
                            playIcon.style.display = "none";
                            pauseIcon.style.display = "";

                            if (isMobile) {
                                heroVideoContainer.style.display = "";
                            }
                        } else {
                            vimeoPlayer.pause();
                            playIcon.style.display = "";
                            pauseIcon.style.display = "none";
                        }
                    })
                    .catch(function (error) {
                        console.error("Error toggling play/pause", error);
                    });
            });
        }
    }

    handleUnmuteMute(vimeoPlayer) {
        if (this.unMuteBtn && vimeoPlayer) {
            const unMuteIcon = document.getElementById("unmute-icon");
            const muteIcon = document.getElementById("mute-icon");

            this.unMuteBtn?.addEventListener("click", (e) => {
                vimeoPlayer
                    .getMuted()
                    .then(function (muted) {
                        if (muted) {
                            vimeoPlayer
                                .setMuted(false)
                                .then(function (muted) {
                                    console.log("Muted off", muted);
                                    unMuteIcon.style.display = "none";
                                    muteIcon.style.display = "";
                                })
                                .catch(function (error) {
                                    console.error(
                                        "Video sound cannot be set to off",
                                        error,
                                    );
                                });
                        } else {
                            vimeoPlayer
                                .setMuted(true)
                                .then(function (muted) {
                                    console.log("Muted on", muted);
                                    unMuteIcon.style.display = "";
                                    muteIcon.style.display = "none";
                                })
                                .catch(function (error) {
                                    console.error(
                                        "Video sound cannot be set to on",
                                        error,
                                    );
                                });
                        }
                    })
                    .catch(function (error) {
                        console.error("Error toggling unmute/mute btn", error);
                    });
            });
        }
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const iframes = [
        document.querySelector("#hero-video iframe"), // Homepage
        document.querySelector("#player iframe"), // Pages and other CPTs
    ];

    for (let iframe of iframes) {
        if (iframe) {
            new VimeoHero(iframe);
        }
    }
});

export default VimeoHero;
