class CogBooking extends HTMLElement {
    constructor() {
        super();

        this.manageDisplay = true; // Whether the component should manage the display property of the elements

        // Define properties
        this.status = this.getAttribute("status") ?? "loading";
        this.lastFew = this.hasAttribute("last-few");
        this.debug = this.hasAttribute("debug");
        this.static = this.hasAttribute("static");

        if (this.hasAttribute("priority-onsale-time")) {
            this.priorityOnSaleTime = parseInt(
                this.getAttribute("priority-onsale-time"),
                10,
            );
        }

        if (this.hasAttribute("general-onsale-time")) {
            this.generalOnSaleTime = parseInt(
                this.getAttribute("general-onsale-time"),
                10,
            );
        }

        // if debug is true append debug div to the component
        if (this.debug) {
            const debug = document.createElement("div");
            debug.classList.add("cog-booking__debug");
            this.append(debug);
        }
    }

    connectedCallback() {
        this.render();
        if (!this.static) {
            this.updateInterval = setInterval(() => this.render(), 1000);
        }
    }

    disconnectedCallback() {
        clearInterval(this.updateInterval);
    }

    render() {
        const currentTimestamp = Math.floor(Date.now() / 1000);
        let state;

        // Determine the current state based on timestamps and sold-out attribute
        if (this.getAttribute("status")?.length > 0) {
            state = this.getAttribute("status");
        } else {
            state = "coming-soon";
        }

        if (state == "sold-out") {
            state = "sold-out";
        } else if (
            this.priorityOnSaleTime &&
            currentTimestamp >= this.priorityOnSaleTime &&
            currentTimestamp <= this.generalOnSaleTime
        ) {
            state = "priority-booking";
        } else if (
            this.generalOnSaleTime &&
            currentTimestamp >= this.generalOnSaleTime
        ) {
            state = "general-booking";

            if (this.lastFew) {
                state = "last-few";
            }
        } else if (!this.generalOnSaleTime) {
            state = "general-booking";
        }

        // Show only the elements that match the current state
        this.querySelectorAll(`[data-booking-status]`).forEach((element) => {
            const bookingStatuses = element.dataset.bookingStatus.split(" ");

            if (bookingStatuses.includes(state)) {
                element.dataset.bookingVisible = "true";

                if (this.manageDisplay) {
                    element.style.display = "";
                }
            } else {
                element.dataset.bookingVisible = "false";

                if (this.manageDisplay) {
                    element.style.display = "none";
                }
            }
        });

        // Debug info
        if (this.debug) {
            const debugString = `Current Time: <b>${new Date(
                currentTimestamp * 1000,
            ).toUTCString()}</b><br>
                Priority onsale: <b>${new Date(
                    this.priorityOnSaleTime * 1000,
                ).toUTCString()}</b><br>
                General onsale: <b>${new Date(
                    this.generalOnSaleTime * 1000,
                ).toUTCString()}</b><br>
                Button State: <b>${state}</b><br>`;

            this.querySelector(".cog-booking__debug").innerHTML = debugString;
        }
    }
}

if (!customElements.get("cog-booking")) {
    customElements.define("cog-booking", CogBooking);
}
