import Disclosure from "../utils/Disclosure.js";

class SiteHeader {
    constructor(element) {
        this.el = element;

        this.init();
    }

    init() {
        document.body.style.setProperty(
            "--header-height",
            this.el.offsetHeight + "px",
        );
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const element = document.querySelector(".site-header");

    if (element) {
        new SiteHeader(element);
    }
});
