class BlogVideo {
    constructor(el) {
        this.el = el;

        this.isMobile = window.matchMedia("(max-width: 576px)").matches;

        this.handleVideoDialog();
    }

    handleVideoDialog() {
        const videoButtons = this.el.querySelectorAll("[data-video-id]");
        if (!videoButtons) {
            return false;
        }

        videoButtons.forEach((button) => {
            let videoId = button.dataset.videoId;

            if (!videoId) {
                console.log("No video ID found for dialog");
                return false;
            }

            let dialogForId = this.el.querySelector(
                `[data-dialog="${videoId}"]`,
            );

            // Open dialog
            button.addEventListener("click", (event) => {
                this.addVideoToDialog(dialogForId);

                dialogForId?.showModal();
            });

            // Get dialog close btn
            let dialogCloseBtn = dialogForId.querySelector(
                "[data-dialog-close]",
            );

            // Close dialog (inside btn)
            dialogCloseBtn?.addEventListener("click", (event) => {
                this.removeVideoFromDialog(dialogForId);

                dialogForId?.close();
            });

            // Handle Escape key.
            if (dialogCloseBtn) {
                document.addEventListener("keyup", function (event) {
                    if (event.key === "Escape" || event.key === "Esc") {
                        dialogCloseBtn.click();
                    }
                });
            }
        });
    }

    addVideoToDialog(dialogForId) {
        const videoIframe = this.getVideofromTemplate(dialogForId);
        const container = dialogForId.querySelector("div.content");

        if (container && videoIframe) {
            container.appendChild(videoIframe);
        }
    }

    removeVideoFromDialog(dialogForId) {
        const videoIframe = dialogForId.querySelector("iframe");
        if (videoIframe) {
            videoIframe.remove();
        }
    }

    getVideofromTemplate(dialogForId) {
        const template = dialogForId.querySelector("template");
        const templateContent = template?.content;

        if (templateContent) {
            // Clone the content (deep clone to include children)
            const clonedContent = document.importNode(templateContent, true);

            return clonedContent;
        } else {
            return false;
        }
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const blogHeaderVideos = document.querySelectorAll("[data-video-image]");
    const isBlogPost = document.body.classList.contains("single-post");

    if (blogHeaderVideos && isBlogPost) {
        blogHeaderVideos.forEach((videoContainer) => {
            new BlogVideo(videoContainer);
        });
    }
});

export default BlogVideo;
