import Player from "@vimeo/player";

class VimeoHero {
    constructor(el, videoSize, isMobile, vimeoForMobileExist, btnPostfix) {
        this.container = el;

        if (videoSize == "mobile") {
            this.controlButtons = document.querySelector(
                `[data-vimeo-buttons="mobile"]`,
            );
        } else {
            this.controlButtons = document.querySelector(
                `[data-vimeo-buttons="desktop"]`,
            );
        }

        this.iframe = el.querySelector("iframe");

        this.isMobile = isMobile;

        this.vimeoForMobileExist = vimeoForMobileExist;

        this.pauseBtn = document.getElementById(`pause-btn${btnPostfix}`);

        this.unMuteBtn = document.getElementById(`unmute-btn${btnPostfix}`);

        // Icons play/pause.
        this.pauseIcon = document.getElementById(`pause-icon${btnPostfix}`);
        this.playIcon = document.getElementById(`play-icon${btnPostfix}`);

        // Icons mute/unmute
        this.unMuteIcon = document.getElementById(`unmute-icon${btnPostfix}`);
        this.muteIcon = document.getElementById(`mute-icon${btnPostfix}`);

        this.handleVideo(vimeoForMobileExist);
    }

    handleVideo(vimeoForMobileExist) {
        const self = this;

        const vimeoPlayer = new Player(this.iframe);

        this.vimeoPlayer = vimeoPlayer;

        const videoCoverImage = document.querySelector(
            "[data-home-video-cover-img]",
        );

        const featuredImage = document.querySelector(
            "[data-hero-feature-image]",
        );

        this.featuredImage = featuredImage;

        // Theres only 1 vimeo. Same for desktop and mobile.
        // This handles it.
        if (this.isMobile && !vimeoForMobileExist) {
            // Hide video container on load.
            self.container.style.display = "none";

            vimeoPlayer.pause();

            // Pause icon visible.
            this.playIcon.style.display = "";
            this.pauseIcon.style.display = "none";

            // Homepage: If there's a video image cover, show it instead of featured image.
            if (videoCoverImage) {
                videoCoverImage.style.display = "";
                featuredImage.style.display = "none";
            }
        }

        this.handlePlayPause();

        this.handleUnmuteMute();
    }

    handlePlayPause() {
        const self = this;

        //console.log("handlePlayPause: ", self.pauseBtn);

        if (self.pauseBtn && self.vimeoPlayer) {
            self.pauseBtn?.addEventListener("click", (e) => {
                self.vimeoPlayer
                    .getPaused()
                    .then(function (paused) {
                        if (paused) {
                            self.vimeoPlayer.play();
                            self.playIcon.style.display = "none";
                            self.pauseIcon.style.display = "";

                            if (self.isMobile) {
                                self.container.style.display = "";
                            }
                        } else {
                            self.vimeoPlayer.pause();
                            self.playIcon.style.display = "";
                            self.pauseIcon.style.display = "none";
                        }
                    })
                    .catch(function (error) {
                        console.error("Error toggling play/pause", error);
                    });
            });
        }
    }

    handleUnmuteMute() {
        const self = this;

        if (self.unMuteBtn && self.vimeoPlayer) {
            self.unMuteBtn?.addEventListener("click", (e) => {
                self.vimeoPlayer
                    .getMuted()
                    .then(function (muted) {
                        if (muted) {
                            self.vimeoPlayer
                                .setMuted(false)
                                .then(function (muted) {
                                    console.log("Muted off", muted);
                                    self.unMuteIcon.style.display = "none";
                                    self.muteIcon.style.display = "";
                                })
                                .catch(function (error) {
                                    console.error(
                                        "Video sound cannot be set to off",
                                        error,
                                    );
                                });
                        } else {
                            self.vimeoPlayer
                                .setMuted(true)
                                .then(function (muted) {
                                    console.log("Muted on", muted);
                                    self.unMuteIcon.style.display = "";
                                    self.muteIcon.style.display = "none";
                                })
                                .catch(function (error) {
                                    console.error(
                                        "Video sound cannot be set to on",
                                        error,
                                    );
                                });
                        }
                    })
                    .catch(function (error) {
                        console.error("Error toggling unmute/mute btn", error);
                    });
            });
        }
    }
}

document.addEventListener("DOMContentLoaded", () => {
    let vimeoHeroVideos = {};
    let btnPostfix = "";

    const iframeContainers = [
        document.getElementById("hero-video"), // Homepage
        document.getElementById("player"), // Pages and other CPTs
        document.getElementById("mobile-player"), // Mobile video(vimeo)
    ];

    const isMobile = window.matchMedia("(max-width: 576px)").matches;

    const vimeoForMobileExist = document.querySelector(
        `[data-vimeo-size="mobile"]`,
    );

    for (let container of iframeContainers) {
        if (container) {
            let videoSize = container.getAttribute("data-vimeo-size");

            if (videoSize == "mobile") {
                btnPostfix = "-mob";
            } else {
                btnPostfix = "";
            }

            vimeoHeroVideos[videoSize] = new VimeoHero(
                container,
                videoSize,
                isMobile,
                vimeoForMobileExist,
                btnPostfix,
            );
        }
    }

    // There are 2 vimeo's videos.
    // These handles that case.
    if (vimeoForMobileExist) {
        if (isMobile) {
            // Show controls for mobile.
            vimeoHeroVideos["mobile"].controlButtons.style.display = "";

            // Removed controls for desktop
            //vimeoHeroVideos["desktop"].controlButtons.style.display = "none";
            vimeoHeroVideos["desktop"].controlButtons?.remove();

            // Hide all videos containers.
            vimeoHeroVideos["desktop"].container.style.display = "none";
            vimeoHeroVideos["mobile"].container.style.display = "none";

            // Pause all videos.
            vimeoHeroVideos["desktop"].vimeoPlayer.pause();
            vimeoHeroVideos["mobile"].vimeoPlayer.pause();

            vimeoHeroVideos["mobile"].playIcon.style.display = "";
            vimeoHeroVideos["mobile"].pauseIcon.style.display = "none";
        } else {
            // Its desktop.

            // Show controls for desktop
            vimeoHeroVideos["desktop"].controlButtons.style.display = "";

            // Hide controls for mobile.
            // vimeoHeroVideos["mobile"].controlButtons.style.display = "none";
            vimeoHeroVideos["mobile"].controlButtons?.remove();

            // Display desktop video
            vimeoHeroVideos["desktop"].container.style.display = "";

            // Hide mobile video
            vimeoHeroVideos["mobile"].container.style.display = "none";

            vimeoHeroVideos["mobile"].vimeoPlayer.pause();
        }
    }
});

export default VimeoHero;
