import Shuffle from "shufflejs";

class ShuffleGrid {
    constructor(element) {
        this.el = element;
        this.init();
    }

    init() {
        this.shuffle();
    }

    shuffle() {
        const sizer = document.querySelector("[data-shuffle-sizer]");
        const shuffleItems = this.el.querySelectorAll("[data-shuffle-item]");

        this.el.style.setProperty(
            "--shuffle-item-width",
            sizer.offsetWidth + "px",
        );

        this.el.shuffle_instance = new Shuffle(this.el, {
            itemSelector: "[data-shuffle-item]",
            sizer: sizer,
        });
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const shuffles = document.querySelectorAll("[data-shuffle]");
    shuffles.forEach((element) => {
        new ShuffleGrid(element);
    });
});

export default ShuffleGrid;
