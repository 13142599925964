class SupportBlock {
    constructor(element) {
        this.supportBlock = element;

        this.form = element.querySelector("[data-support-form]");

        this.submitBtn = this.form.querySelector('[type="submit"]');
        this.donationText = this.form.querySelector("[data-amount-message]");
        this.other_amount = this.form.querySelector(
            "[data-fieldset-other-amount]",
        );

        // Methods.
        this.amountsHandler();
        this.submitHandler();
        this.inputHandler();
    }

    amountsHandler() {
        const amountsRadios = this.form.querySelectorAll('[type="radio"]');

        if (amountsRadios) {
            amountsRadios.forEach((amount) => {
                amount.addEventListener("click", (event) => {
                    this.other_amount.value = "";

                    let theAmount = event.currentTarget;

                    this.updateButtonLabel(theAmount.value);

                    let text = theAmount.dataset.amountText
                        ? theAmount.dataset.amountText
                        : "";

                    this.updateDonationText(text);

                    let imageId = theAmount.dataset.amountImage
                        ? theAmount.dataset.amountImage
                        : false;

                    this.updateCurrentImage(imageId);
                });
            });
        }
    }

    updateDonationText(text) {
        if (this.donationText) {
            this.donationText.textContent = text;
        }
    }

    updateButtonLabel(number) {
        if (this.submitBtn) {
            this.submitBtn.textContent = `Donate £${number} today`;
        }
    }

    updateCurrentImage(id) {
        if (!id) return;

        const prevImage = this.supportBlock.querySelector(
            "[data-item-image-id].active",
        );
        const newImage = this.supportBlock.querySelector(
            '[data-item-image-id="' + id + '"]',
        );
        prevImage.classList.remove("active");
        newImage.classList.add("active");
    }

    submitHandler() {
        this.form.addEventListener("submit", (event) => {
            event.preventDefault();

            const formValues = this.getFormValues(this.form);

            const donationAmount = this.getDonationAmount(formValues);

            if (
                donationAmount > 0 &&
                this.isValidURL(formValues.fund_page_link)
            ) {
                let fundURL =
                    formValues.fund_page_link + `?amount=${donationAmount}`;

                window.location.assign(fundURL);
            } else {
                console.log(
                    "Donation value is not set or the page fund URL is not set/valid, cannot redirect to fund page",
                );
            }
        });
    }

    inputHandler() {
        if (this.other_amount) {
            this.other_amount.addEventListener("input", (event) => {
                const currentValue = event.currentTarget.value;

                this.updateButtonLabel(currentValue);
            });
        }
    }

    getFormValues(form) {
        if (!form) {
            return [];
        }

        const formData = new FormData(form);
        let paramsVals = {};

        // Add each name/value pair to the object
        for (let [name, value] of formData) {
            paramsVals[name] = value;
        }

        return paramsVals;
    }

    getDonationAmount(formValues) {
        let amount;

        if (formValues.other_amount) {
            amount = Number.parseFloat(formValues.other_amount).toFixed(2);
        } else if (formValues.amount) {
            amount = Number.parseFloat(formValues.amount).toFixed(2);
        }

        if (!isNaN(amount)) {
            return amount;
        } else {
            amount = 0;
            return amount;
        }
    }

    isValidURL(url) {
        try {
            new URL(url);
            return true;
        } catch (e) {
            return false;
        }
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const supportBlocks = document.querySelectorAll("[data-support-block]");

    if (supportBlocks) {
        supportBlocks.forEach((element) => {
            new SupportBlock(element);
        });
    }
});

export default SupportBlock;
