class SupportUsButton {
    constructor(el) {
        this.isMobile = window.matchMedia("(max-width: 576px)").matches;

        this.button = el;
        this.dialog = el.nextElementSibling;

        this.button.addEventListener("click", (event) => {
            event.preventDefault();

            this.addVideoToDialog(this.dialog);

            this.dialog?.showModal();
        });

        // Get dialog close btn
        let dialogCloseBtn = this.dialog?.querySelector("[data-dialog-close]");

        dialogCloseBtn?.addEventListener("click", (event) => {
            this.removeVideoFromDialog(this.dialog);

            this.dialog?.close();
        });

        // Handle Escape key.
        if (dialogCloseBtn) {
            document.addEventListener("keyup", function (event) {
                if (event.key === "Escape" || event.key === "Esc") {
                    dialogCloseBtn.click();
                }
            });
        }
    }

    addVideoToDialog(dialogForId) {
        const videoIframe = this.getVideofromTemplate(dialogForId);
        const container = dialogForId.querySelector(
            "[data-video-dialog-container]",
        );

        if (container && videoIframe) {
            container.appendChild(videoIframe);
        }
    }

    removeVideoFromDialog(dialogForId) {
        const videoIframe = dialogForId.querySelector("iframe");
        if (videoIframe) {
            videoIframe.remove();
        }
    }

    getVideofromTemplate(dialogForId) {
        const template = dialogForId.querySelector("template");
        const templateContent = template?.content;

        if (templateContent) {
            // Clone the content (deep clone to include children)
            const clonedContent = document.importNode(templateContent, true);

            return clonedContent;
        } else {
            return false;
        }
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const supportUsBtn = document.querySelectorAll("[data-support-us-btn]");

    if (supportUsBtn) {
        supportUsBtn.forEach((btn) => {
            new SupportUsButton(btn);
        });
    }
});

export default SupportUsButton;
