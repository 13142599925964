class LoadMoreInstances {
    constructor(el) {
        this.loadMoreBtn = el;
        this.isMobile = window.matchMedia("(max-width: 576px)").matches;

        this.howMany = parseInt(this.loadMoreBtn.dataset.howManyItems);
        this.pageNum = parseInt(this.loadMoreBtn.dataset.pageNum);

        this.instancesContainer = this.loadMoreBtn.previousElementSibling;

        if (!this.instancesContainer) {
            return;
        }

        this.instances = this.instancesContainer.querySelectorAll(
            "ul.event-instances__items .event-instance, .related-content__cards.grid article.card",
        );

        this.initInstancesDisplay();
        this.handleDisplayInstances();
    }

    initInstancesDisplay() {
        if (this.instances.length > this.howMany) {
            const iniHiddenInstances = [...this.instances].slice(this.howMany);

            if (iniHiddenInstances.length) {
                iniHiddenInstances.forEach((instance) => {
                    instance.classList.add("hidden-instance");
                });
            }
        }
    }

    handleDisplayInstances() {
        this.loadMoreBtn.addEventListener("click", (e) => {
            e.preventDefault();

            this.displayNextChunk();
        });
    }

    displayNextChunk() {
        this.howMany = parseInt(this.loadMoreBtn.dataset.howManyItems);
        this.pageNum = parseInt(this.loadMoreBtn.dataset.pageNum);

        let chunk = [];

        let instancesArr = [...this.instances];

        let start = this.pageNum * this.howMany;
        let end = start + this.howMany;

        if (end < instancesArr.length) {
            chunk = instancesArr.slice(start, end);
        } else {
            chunk = instancesArr.slice(start);
            this.loadMoreBtn.dataset.allShown = "true";
        }

        console.log("start , end, chunk  ", start, end, chunk, this.howMany);

        this.loadMoreBtn.dataset.pageNum = parseInt(this.pageNum) + 1;

        this.showChunk(chunk);

        if (this.loadMoreBtn.dataset.allShown == "true") {
            // Remove button.
            this.loadMoreBtn.remove();
        }
    }

    showChunk(chunk) {
        chunk.forEach((instance) => {
            instance.classList.remove("hidden-instance");
        });
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const loadMoreBtns = document.querySelectorAll("[data-load-more-btn]");

    if (loadMoreBtns.length) {
        loadMoreBtns.forEach((button) => {
            new LoadMoreInstances(button);
        });
    }
});

export default LoadMoreInstances;
